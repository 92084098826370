@import url('../../Themes/DefaultVariables.module.css');

.container {
  display: flex;
  flex-wrap: wrap;
  width: var(--full);
  align-items: center;
  justify-content: center;
  padding-bottom: var(--paddingSm);
  gap: var(--gapSm);
}

.backgroundImage {
  height: 500px;
  margin-top: var(--backgroundImage);
  width: var(--full);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backgroundImage span {
  font-size: var(--xl);
  font-weight: var(--semiBold);
}

.descriptionContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: var(--full);
}

.environmentDescription {
  width: var(--fiftyPercent);
  border-right: 1px solid #808080;
  display: inline;
  flex-wrap: wrap;
  padding: var(--paddingSm);
}

.blockTitle {
  font-size: var(--md);
  color: var(--regularText);
  font-weight: var(--semiBold);
  width: var(--full);
}

.blockText {
  font-size: var(--sm);
  color: var(--regularText);
  font-weight: var(--regular);
  width: var(--full);
  text-align: left;
}

.activityInformation {
  width: var(--fiftyPercent);
  border-left: 1px solid #808080;
  display: flex;
  flex-wrap: wrap;
  padding: var(--paddingSm);
}

.suggestionsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  justify-content: flex-start;
  max-width: 1200px;
  margin-top: var(--marginXl);
  padding: var(--paddingSm);
  border-top: 1px solid #808080;
  width: var(--full);
  min-height: 100px;
}

.sectionTitle {
  font-size: var(--lg);
  color: var(--regularText);
  font-weight: var(--semiBold);
  width: var(--full);
  text-align: left;
  margin-bottom: var(--marginSm);
}

.sectionText {
  font-size: var(--sm);
  color: var(--regularText);
  font-weight: var(--semiBold);
  width: var(--full);
  text-align: left;
}

.suggestedActivityContainer {
  display: flex;
  flex-wrap: nowrap;
  gap: var(--gapSm);
  width: var(--fiftyPercent);
}

.suggestedActivityIcon {
  height: 20px;
  width: 20px;
}

.socialMediaBlock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gapMd);
  flex-wrap: wrap;
  width: var(--full);
  border-bottom: 1px solid #808080;
  border-top: 1px solid #808080;
  height: 100px;
  max-width: 1200px;
}

.socialMediaBlock span {
  color: var(--regularText);
  font-size: var(--sm);
  font-weight: var(--semiBold);
}

.sliderContainer {
  max-height: 600px;
  max-width: 900px;
  margin-top: var(--marginXl);
}

.sliderImage {
  height: var(--full);
  width: var(--full);
  max-height: 600px;
}

.generalInformationContainer {
  display: flex;
  flex-wrap: wrap;
  border-left: 1px solid #808080;
  width: var(--fiftyPercent);
  padding: var(--paddingSm);
}

.singleBlockContainer {
  width: var(--full);
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #808080;
  min-height: 40px;
  padding: var(--paddingXs) var(--paddingSm);
}

.generalInformationTitle {
  color: var(--regularText);
  font-weight: var(--semiBold);
  font-size: var(--sm);
  width: var(--full);
  text-align: center;
}

.nameBlock {
  display: flex;
  flex-wrap: nowrap;
}

.generalInformationName {
  width: 200px;
  color: var(--regularText);
  font-weight: var(--semiBold);
  font-size: var(--xs);
  text-align: left;
  margin-left: var(--marginSm);
}

.generalInformation,
.generalInformation:hover {
  color: var(--regularText);
  font-weight: var(--regular);
  font-size: var(--xs);
  text-decoration: none;
}

.generalInformationIcon {
  height: 20px;
  width: 20px;
}

.blockContainer {
  width: var(--eightyPercent);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.shareContainer {
  max-width: 1200px;
  width: var(--full);
}

/* Added */

.mapContainer {
  height: 100px;
  border-top: 1px solid #808080;
  border-bottom: 1px solid #808080;
  margin-top: var(--marginXl);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: var(--full);
}

.mapButton {
  background-color: inherit;
  background: none;
  border: none;
  outline: none;
  color: var(--regularText);
  font-weight: var(--semiBold);
  text-align: center;
  align-items: center;
  display: flex;
  padding: var(--paddingNull);
}

.locationIcon {
  width: 25px;
}

.mapText {
  color: #808080;
  font-weight: var(--semiBold);
}

.informationContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--marginXl);
  max-width: 1200px;
}

.informationHistoryContainer {
  display: flex;
  flex-wrap: wrap;
  border-right: 1px solid #808080;
  width: var(--fiftyPercent);
  padding: var(--paddingSm);
}

.historyTitle {
  color: var(--regularText);
  font-size: var(--sm);
  font-weight: var(--semiBold);
  text-align: center;
  width: 1var (--full);
  margin-bottom: var(--marginSm);
}

.historyText {
  color: var(--regularText);
  font-size: var(--xs);
  font-weight: var(--semiBold);
  text-align: left;
  width: var(--full);
}

.suggestionsItemContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: var(--full);
  gap: var(--gapLg);
  margin-bottom: var(--marginLg);
}

.suggestionsBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 45%;
}

.suggestionsItem {
  display: flex;
  width: var(--full);
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  margin-top: var(--marginLg);
}

.suggestionsIcon {
  height: 30px;
}

.suggestionsName {
  font-size: var(--xs);
  font-weight: var(--semiBold);
  color: var(--regularText);
  display: flex;
  min-height: 30px;
  align-items: center;
  justify-content: flex-start;
  padding: var(--paddingNull) var(--paddingLg);
  width: var(--full);
}

.suggestionsSubtitle {
  font-size: var(--md);
  color: var(--regularText);
  font-weight: var(--bold);
  margin: var(--marginLg) var(--marginNull);
  width: var(--full);
}

.anchroLink,
.anchroLink:hover {
  text-decoration: none;
  color: var(--regularText);
}

@media screen and (max-width: 650px) {
  .environmentDescription {
    width: var(--full);
    border-right: 0;
    border-bottom: 1px solid #808080;
  }
  .activityInformation {
    width: var(--full);
    border-left: 0;
    border-top: 1px solid #808080;
  }
  .suggestionsBlock {
    width: var(--full);
  }
  .suggestionsName {
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .suggestedActivityContainer {
    width: var(--full);
  }
  .shareContainer {
    width: var(--full);
  }
  .blockContainer {
    width: var(--full);
  }
}

@media screen and (max-width: 1170px) {
  .informationHistoryContainer {
    width: var(--full);
    border-right: 0;
  }
  .generalInformationContainer {
    width: var(--full);
    border-left: 0;
  }
}

@media screen and (max-width: 520px) {
  .generalInformation {
    width: var(--full);
  }
}

.blockContainer {
  display: flex;
  flex-wrap: wrap;
  width: var(--full);
  max-width: var(--detailPageMaxWidth);
  margin: 0 auto;
}

.titleContainer {
  min-height: 100px;
  background-color: var(--primaryColor);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: var(--full);
  margin: 20px 0px;
}

.titleContainer h2 {
  font-weight: var(--semiBold);
  color: var(--whiteText);
  font-size: var(--xl);
}

.descriptionContainer {
  width: var(--full);
  border-top: 1px solid var(--grayText);
  border-bottom: 1px solid var(--grayText);
  padding: 20px 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.titleText {
  margin-bottom: 0;
  font-size: 16px;
  color: var(--regularText);
  font-weight: var(--semiBold);
  display: inline;
  white-space: pre-line;
}

.subtitle {
  margin-bottom: 0;
  font-size: 18px;
  color: var(--regularText);
  font-weight: var(--semiBold);
  display: inline;
  white-space: pre-line;
}

.bodyText {
  margin-bottom: 0;
  font-size: 16px;
  color: var(--regularText);
  font-weight: var(--semiBold);
  display: inline;
  white-space: pre-line;
}

.anchorText,
.anchorText:hover {
  font-size: 16px;
  text-decoration: none;
  color: var(--pageTitleLine);
  font-weight: var(--semiBold);
}
