@import url('../Themes/DefaultVariables.module.css');

.container {
  width: var(--full);
  height: var(--full);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.backgroundImage {
  height: 500px;
  margin-top: var(--backgroundImage);
  width: var(--full);
  display: flex;
  align-items: center;
  justify-content: center;
}

.coverImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
}

.titleContainer {
  width: var(--full);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.titleContainer h1 {
  font-size: var(--h1);
  color: var(--whiteText);
  font-weight: var(--semiBold);
}

.historyContainer {
  width: var(--eightyPercent);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: var(--marginXl);
}

.pageBlocks {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: var(--marginLg);
  min-height: 450px;
}

.pageBlocksReverse {
  flex-direction: row-reverse;
}

.blockImageContainer {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: var(--fortyPercent);
  height: 450px;
  margin-top: 20px;
}

.blockImage {
  width: var(--full);
  height: var(--full);
}

.textBlock {
  display: flex;
  width: var(--fiftyPercent);
  flex-wrap: wrap;
  padding: var(--paddingLg) var(--paddingXl);
}

.articleTitle {
  font-size: var(--md);
  color: var(--regularText);
  font-weight: var(--bold);
  width: var(--full);
}

.articleText {
  font-size: var(--xs);
  color: var(--regularText);
  font-weight: var(--semiBold);
  width: var(--full);
  text-align: left;
  white-space: pre-line;
  word-break: normal !important;
}

.suggestionsItemContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: var(--full);
  gap: var(--gapLg);
  margin-bottom: var(--marginLg);
}

.suggestionsBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 45%;
}

.suggestionsItem {
  display: flex;
  width: var(--full);
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  margin-top: var(--marginLg);
}

.suggestionsIcon {
  height: 30px;
}

.suggestionsName {
  font-size: var(--sm);
  font-weight: var(--bold);
  color: var(--regularText);
  display: flex;
  min-height: 30px;
  align-items: center;
  justify-content: flex-start;
  padding: var(--paddingNull) var(--paddingLg);
  width: var(--full);
}

.suggestionsSubtitle {
  font-size: var(--md);
  color: var(--regularText);
  font-weight: var(--bold);
  margin: var(--marginLg) var(--marginNull);
  width: var(--full);
}

.anchoreTextDiv {
  width: 100%;
  max-width: 900px;
  border-top: 1px solid var(--grayText);
  padding: 20px 0px;
}

.anchoreTextDiv a {
  color: var(--primaryColor);
  text-decoration: none;
  font-size: var(--xs);
  font-weight: var(--semiBold);
}

.anchoreTextDiv span {
  color: var(--regularText);
  font-size: var(--xs);
  font-weight: var(--semiBold);
}

.titleContainer h2 {
  font-weight: var(--semiBold);
  color: var(--whiteText);
  font-size: var(--xl);
}

.descriptionContainer {
  width: var(--sixtyPercent);
  padding: 20px 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.titleText {
  margin-bottom: 0;
  font-size: var(--xs);
  color: var(--regularText);
  font-weight: var(--semiBold);
  display: inline;
}

.anchorText,
.anchorText:hover {
  font-size: var(--xs);
  text-decoration: none;
  color: var(--pageTitleLine);
  font-weight: var(--semiBold);
}

.textLeft {
  text-align: left;
}

@media screen and (max-width: 1200px) {
  .historyContainer {
    width: var(--full);
    padding: 0 20px;
  }
}

@media screen and (max-width: 1000px) {
  .articleTitle {
    font-size: var(--sm);
  }

  .articleText {
    font-size: calc(var(--xs) - 2px);
  }
}

@media screen and (max-width: 800px) {
  .textBlock {
    width: var(--full);
    max-width: var(--full);
  }

  .blockImageContainer {
    width: var(--full);
  }

  .suggestionsBlock {
    width: var(--full);
  }

  .suggestionsName {
    justify-content: center;
  }
  .descriptionContainer {
    width: var(--full);
  }
  .historyContainer {
    width: var(--full);
    padding: 0 var(--paddingSm);
  }
  .pageBlocks {
    flex-direction: column;
    width: 100%;
  }
  .blockImageContainer {
    max-width: unset;
  }
}
